import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { filterUndefined, getProp } from '@core/shared/util';
import { PagedDataSource } from '@core/ui';
import { ClinicSupplierFacade } from '@mp/supplier-management/clinic-suppliers/data-access';
import { ClinicSupplierListComponent } from '@mp/supplier-management/clinic-suppliers/ui';
import { ClinicSupplierBasic } from '@mp/supplier-management/common/domain';

type FetchParams = { searchTerm: string };

@Component({
  selector: 'mpsm-clinic-suppliers-overview',
  standalone: true,
  templateUrl: './clinic-suppliers-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, ClinicSupplierListComponent],
})
export class ClinicSuppliersOverviewComponent {
  readonly dataSource: PagedDataSource<ClinicSupplierBasic, FetchParams> = this.facade.dataSource;
  readonly resultCount$: Observable<number> = this.facade.pagination$.pipe(filterUndefined(), getProp('totalRowCount'));

  readonly isProcessingExport$: Observable<boolean> = this.facade.isProcessingExport$;

  constructor(private readonly facade: ClinicSupplierFacade) {
    this.handleSearch('');
  }

  handleSearch(searchTerm: string): void {
    this.dataSource.updateQueryParams({ searchTerm });
  }

  exportAll(): void {
    this.facade.exportAll();
  }
}
