import { permissions } from '@mpk/shared/domain';

/**
 * Permissions for the clinic suppliers
 */
enum ClinicSuppliersPermissionsEnum {
  None = 0,

  /**
   * Clinic suppliers in catalog management can be viewed
   */
  ViewClinicSuppliers = 1,

  /**
   * Clinic suppliers in catalog management can be viewed and assigned
   */
  ManageClinicSuppliers = 2 | ViewClinicSuppliers,
}

export const ClinicSuppliersPermissions = permissions(ClinicSuppliersPermissionsEnum, {
  featureId: 'ClinicSuppliers',
});
